import { SelectInput, TextInput } from '@atoms';
import clsx from 'clsx';
import Image from 'next/image';
import { useState } from 'react';

export interface Option {
  label: string;
  value: string | number;
}

interface Props {
  name?: string;
  index?: number;
  typeOptions?: Option[];
  showLabels?: boolean;
  remove?: (index: number) => void;
}

const VoucherFields = ({ name, index, typeOptions, showLabels = false, remove }: Props) => {
  const fieldNamePrefix = name !== undefined ? `${name}.${index}.` : '';
  const [showOptions, setShowOptions] = useState(false);

  return (
    <div
      className="row mt-3"
      onMouseEnter={() => setShowOptions(true)}
      onMouseLeave={() => setShowOptions(false)}
      onTouchStart={() => setShowOptions(!showOptions)}
    >
      <div className="col">
        <TextInput
          name={`${fieldNamePrefix}name`}
          autoComplete="off"
          type="text"
          label={showLabels ? 'Name' : undefined}
          variant="compact"
        />
      </div>
      <div className="col">
        <TextInput
          name={`${fieldNamePrefix}quantity`}
          autoComplete="off"
          type="number"
          label={showLabels ? 'Quantity' : undefined}
          variant="compact"
        />
      </div>
      <div className="col">
        <SelectInput
          name={`${fieldNamePrefix}size`}
          options={typeOptions}
          label={showLabels ? 'Size' : undefined}
          placeholder="Select from list"
          variant="compact"
        />
      </div>
      {index !== 0 && (
        <div
          className={clsx('col-2 mt-2 pt-1 transition-all duration-300 transition-ease', {
            'opacity-100': showOptions,
            'opacity-0': !showOptions,
          })}
        >
          <button
            type="button"
            className="btn btn-outline shadow-none m-0 p-0"
            onClick={() => index && remove?.(index)}
            data-test-id={`remove-voucher-${index}`}
          >
            <Image
              src="/assets/img/icons/small-close-icon.svg"
              alt="remove"
              width={20}
              height={20}
            />
          </button>
        </div>
      )}
    </div>
  );
};
export default VoucherFields;
