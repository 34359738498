import { RadioInput } from '@atoms';
import clsx from 'clsx';

interface Props {
  label: string;
  name: string;
  variant?: 'main' | 'bold';
}

const Checkitem = ({ label, name, variant = 'main' }: Props) => (
  <div className="mb-4">
    <label
      className={clsx('text-cyan-blue mb-0', variant === 'main' && 'fw-normal')}
      htmlFor={`input-${name}`}
    >
      {label}
    </label>
    <div className="d-flex align-items-center">
      <div className="me-5">
        <RadioInput name={name} label="Yes" value="yes" variant="checklist" />
      </div>
      <RadioInput name={name} label="No" value="no" variant="checklist" />
    </div>
  </div>
);
export default Checkitem;
