import { AssetCategoryTag } from '@atoms';
import type { AssetCategory } from '@schemas';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';

interface Props {
  focusedCategory?: number;
  categories: AssetCategory[];
  onCategoryFocus: (categoryId?: number) => void;
}

const AssetsCategories = ({ focusedCategory, categories, onCategoryFocus }: Props) => {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (container.current) {
      const category = container.current.querySelector(`[data-category="${focusedCategory}"]`);

      if (category) {
        category.scrollIntoView({ inline: 'center', block: 'center' });
      }
    }
  }, [focusedCategory]);

  return (
    <div
      className={clsx(
        'px-5 gap-1 d-flex w-100 align-items-center',
        'py-4 overflow-x-auto overflow-y-hidden no-scrollbar',
      )}
      ref={container}
    >
      <div className="d-flex mx-auto my-5">
        {categories.map(({ id, name }) => (
          <AssetCategoryTag
            id={id}
            selected={id === focusedCategory}
            key={id}
            name={name}
            onClick={() => onCategoryFocus(id)}
          />
        ))}
      </div>
    </div>
  );
};

export default AssetsCategories;
