import Modal from '@molecules/Modal/Modal';
import Image from 'next/image';
import Router from 'next/router';
import { useCallback } from 'react';

interface Props {
  onClose: () => void;
  open: boolean;
  eventId: number;
}

const EventCreatedModal = ({ open, onClose, eventId }: Props) => {
  const handleClose = useCallback(() => {
    Router.push(`/events/${eventId}`);
    onClose();
  }, [eventId, onClose]);

  const handleAction = useCallback(() => {
    Router.push(`/themes/new?eventId=${eventId}`);
    onClose();
  }, [eventId, onClose]);

  return (
    <Modal
      open={open}
      title="Event succesfully created!"
      onClose={handleClose}
      name="event-created"
      actionText="Create a Theme"
      closeText="Maybe later"
      onAction={handleAction}
    >
      <div className="text-center">
        <Image src="/assets/img/event-created.svg" alt="Event created" width={288} height={258} />
        <p className="mt-4 text-dark">
          <b>Congratulations, you have created an event!</b>
          <br />
          Next, please create a Theme for your event.
        </p>
      </div>
    </Modal>
  );
};

export default EventCreatedModal;
