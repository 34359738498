import { createContext } from 'react';

export interface TextureModifierValues {
  color0?: string;
  color00?: string;
  color1?: string;
  color2?: string;
  color3?: string;
  color4?: string;
  color5?: string;
  placementFrontTexture?: string;
  placementBackTexture?: string;
  placementShoulderTexture?: string;
  placementLeftTexture?: string;
  placementRightTexture?: string;
  placementPlaqueTexture?: string;
  placementHatTexture?: string;
  placementHat2Texture?: string;
  isDynamic?: boolean;
  setTexture: (name: string, texture: string) => void;
}

export const TextureModifierContext = createContext<TextureModifierValues>({
  setTexture: () => {
    // do nothing
  },
});
