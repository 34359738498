interface Props {
  title?: string;
  subtitle?: string;
  children: React.ReactNode;
  color?: 'light' | 'white';
}

const FormSection = ({ title, subtitle, color = 'light', children }: Props) => (
  <div className={`card bg-${color} shadow-none mb-3`}>
    <div className="card-body px-0">
      {title && <h3 className="fw-bolder card-title text-black">{title}</h3>}
      {subtitle && <h6 className="card-subtitle mb-3 text-muted">{subtitle}</h6>}

      <div className="row">{children}</div>
    </div>
  </div>
);
export default FormSection;
