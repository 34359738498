import { Spinner } from '@atoms';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';

import styles from './LoadingBackdrop.module.scss';

interface Props {
  fullSize?: boolean;
  loading: boolean;
  name: string;
}

const LoadingBackdrop = ({ fullSize, children, loading, name }: PropsWithChildren<Props>) => (
  <div className="position-relative">
    {children}
    {loading && (
      <div
        className={clsx(
          styles['backdrop'],
          fullSize ? 'modal-backdrop' : 'position-absolute w-100 h-100 top-0 start-0 rounded-5',
        )}
        data-test-id={`loading-backdrop-${name}`}
      >
        <div className="position-absolute top-50 start-50 translate-middle">
          <Spinner name={name} />
        </div>
      </div>
    )}
  </div>
);
export default LoadingBackdrop;
