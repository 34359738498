import { ColorInput, Dropdown } from '@atoms';
import clsx from 'clsx';
import { useState } from 'react';

import styles from './ColorFormGroup.module.scss';

const availableFormats = ['Hex', 'RGB'];

interface Props {
  title: string;
  name: string;
}

const ColorFormGroup = ({ title, name }: Props) => {
  const [format, setFormat] = useState<'Hex' | 'RGB'>('Hex');

  return (
    <div
      className={clsx(
        'justify-content-center rounded-6',
        'border border-white border-3',
        'bg-transparent-white',
        'color-form-group mb-3',
        'p-2 px-xl-3',
      )}
    >
      <h6 className="text-uppercase fw-bolder text-white">{title}</h6>
      <div className={clsx('d-inline-flex m-2', styles['color-form-group-container'])}>
        <Dropdown
          options={availableFormats}
          onValueChange={(format) => setFormat(format as 'Hex' | 'RGB')}
          name={`${name}-format`}
        />
        <ColorInput name={name} value="" format={format} />
      </div>
    </div>
  );
};

export default ColorFormGroup;
