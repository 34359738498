import { LoadingIndicator, Tooltip } from '@atoms';
import { TextureModifierContext } from '@contexts/TextureModifierContext';
import clsx from 'clsx';
import Image from 'next/image';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { readFile } from 'utils/FileUtils';

import styles from './TextureUploader.module.scss';

interface Props {
  title: string;
  name: string;
  value?: string | { url: string };
  placement?: 'top' | 'right' | 'left' | 'bottom';
  minWidth: number;
  minHeight: number;
}

const TextureUploader = ({
  title,
  name,
  value,
  minWidth,
  minHeight,
  placement = 'right',
}: Props) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const { setTexture } = useContext(TextureModifierContext);

  const { register, setValue } = useFormContext();
  const { name: inputName } = register(name);
  const [image, setImage] = useState<string | undefined | null>(
    value && (value instanceof Object ? value.url : value),
  );
  const url = typeof value === 'object' ? value?.url : undefined;
  const id = `input-${name}`;

  useEffect(() => {
    if (url === null) return;
    setValue(inputName, url);
  }, [setValue, inputName, url]);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      readFile(
        event.target.files && event.target.files[0],
        () => setLoading(true),
        (objectUrl) => {
          setLoading(false);

          const image = document.createElement('img');

          image.onload = function () {
            const height = image.height;
            const width = image.width;

            const accept = width >= minWidth || height >= minHeight;

            if (accept && event.target.files) {
              setValue(inputName, event.target.files[0]);
              setImage(objectUrl);
              setTexture(name, objectUrl ?? '');
              setError(false);
              return true;
            }

            setError(true);
            setValue(inputName, undefined);
            alert(`Height and Width must be equal or greater than ${minWidth}x${minHeight}.`);
            return false;
          };

          if (objectUrl) {
            image.src = objectUrl;
          }
        },
      );
    },
    [inputName, minHeight, minWidth, setValue, setTexture, name],
  );

  const tooltipText = useMemo(
    () =>
      `The minimum dimensions for this image are ${minWidth} - ${minHeight}. ` +
      'Your image must be at least this large in one dimension to be compatible.',
    [minHeight, minWidth],
  );

  return (
    <Tooltip name={`${name}-tooltip`} placement={placement} text={tooltipText} show={showTooltip}>
      <div className="mb-2">
        <div
          className={clsx(
            styles['texture-uploader'],
            'border border-white border-3',
            'p-2 px-xl-3 rounded-top-5 rounded-bottom-5',
            'position-relative d-flex flex-column',
            'bg-transparent-white',
          )}
        >
          {image && (
            <label htmlFor={id} className={clsx('position-absolute end-0 top-0', 'mt-2 me-2')}>
              <Image
                height={24}
                width={24}
                data-test-id={`new-upload-${name.replace(/\s/g, '-')}`}
                alt="New upload"
                src="/assets/img/icons/spinning-arrows.svg"
              />
            </label>
          )}

          <div className="d-flex align-items-center mb-2">
            <h6
              className={clsx(
                'fw-bolder m-0 me-2',
                error ? 'text-danger' : 'text-white',
                'text-uppercase',
              )}
            >
              {title}
            </h6>
            <div
              className={clsx(
                'bg-white rounded-circle text-center',
                'fw-bolder',
                styles['info-button'],
              )}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              ?
            </div>
          </div>
          {loading && (
            <div className="m-auto">
              <LoadingIndicator />
            </div>
          )}
          <div className={clsx('my-auto px-1 px-xxl-4', (loading || image) && 'd-none')}>
            <div className="position-relative">
              <label htmlFor={id} className="w-100 mx-0">
                <div
                  className="form-control text-center border-1 border-primary"
                  data-test-id={`file-input-button-${name.replace(/\s/g, '-')}`}
                  role="button"
                >
                  <h6 className="mx-1 mx-xxl-3 mb-0 fs-6 text-primary fw-bolder">Upload file</h6>
                </div>
              </label>
            </div>
          </div>
          <input
            data-test-id={`texture-input-${name.replace(/\s/g, '-')}`}
            type="file"
            id={id}
            className={'w-100 h-100 opacity-0 position-absolute start-0 top-0'}
            name={inputName}
            onChange={handleOnChange}
          />
          {image && !loading && (
            <div className="position-relative h-100 p-1 py-4">
              <Image
                data-test-id={`texture-input-image-${name.replace(/\s/g, '-')}`}
                src={image}
                className="object-fit-contain"
                alt=""
                layout="fill"
              />
            </div>
          )}
        </div>
      </div>
    </Tooltip>
  );
};
export default TextureUploader;
