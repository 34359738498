import { FilledIcon } from '@atoms';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import styles from './AccordionItem.module.scss';

interface MenuSubItem {
  title: string;
  link: string;
  id: string;
}

interface Props {
  id: string;
  title: string;
  icon: string;
  link: string;
  subItems: MenuSubItem[];
  active: boolean;
  setActiveItem: () => void;
}

const AccordionItem = ({ title, icon, id, subItems, active, link, setActiveItem }: Props) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const router = useRouter();

  useEffect(() => {
    setCollapsed(!active);
  }, [active]);

  const toggleAccordion = () => {
    if (active) {
      setCollapsed((prevState) => !prevState);
    } else {
      setActiveItem();
    }
  };

  const itemMenuActive = () => (active ? `rounded-3 bg-highlight` : 'bg-transparent');

  return (
    <div>
      {subItems.length > 0 ? (
        <div className="accordion" id={'accordion' + id}>
          <div className="accordion-item border-0">
            <button
              data-test-id={`accordion-button-${id}`}
              onClick={() => toggleAccordion()}
              id={'heading' + id}
              className={clsx(
                styles['accordion-button'],
                'accordion-button p-2 my-2 shadow-none',
                itemMenuActive(),
              )}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={'#collapse' + id}
              aria-expanded="true"
              aria-controls={'collapse' + id}
            >
              <FilledIcon source={icon} alt={title[0]} />
              <h6 className={`fw-bolder mx-2 mb-0 ${active && 'text-white'}`}>{title}</h6>
            </button>
            <div
              id={'collapse' + id}
              className={clsx('accordion-collapse collapse border-0', !collapsed && 'show')}
              aria-labelledby={'heading' + id}
              data-bs-parent={'#accordion' + id}
            >
              <div className="accordion-body py-0 text-dark">
                <ul className="px-3">
                  {subItems.map((subItem, index) => (
                    <li
                      className={clsx(
                        'fs-6',
                        'py-1',
                        subItem.link === router.asPath && 'fw-bolder',
                      )}
                      key={index}
                    >
                      <Link href={subItem.link} passHref>
                        <a data-test-id={`accordion-sub-item-${subItem.id}`}>{subItem.title}</a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Link href={link} passHref>
          <a
            data-test-id={`accordion-button-${id}`}
            className={clsx('accordion-button p-2 my-2 shadow-none', itemMenuActive())}
          >
            <FilledIcon source={icon} alt={title[0]} />
            <h6 className={clsx('fw-bolder', 'mx-2', 'mb-0', active && 'text-white')}>{title}</h6>
          </a>
        </Link>
      )}
    </div>
  );
};

export default AccordionItem;
