import DateBox from '@atoms/DateBox/DateBox';
import LinkButton from '@atoms/LinkButton/LinkButton';

interface Props {
  id: number;
  name: string;
  address?: string;
  startDate?: Date;
  ready?: boolean;
}

const EventCard = ({ id, name, address, startDate, ready }: Props) => (
  <div className="card shadow-none border-1">
    <div className="card-body row g-0 p-3">
      <div className="col d-flex align-items-center">
        <DateBox startDate={startDate} ready={ready} />
        <div>
          <h5 className="card-title fw-bolder" data-test-id={`event-card-${id}`}>
            {name}
          </h5>
          <h5 className="card-text">{address}</h5>
        </div>
      </div>

      <div className="col-3 d-flex justify-content-end align-self-center">
        <LinkButton name={`manage-event-${id}`} size="lg" href={`/events/${id}`} color="blue">
          <span className="white-space-pre fs-5 p-0 p-xxl-2">Manage</span>
        </LinkButton>
      </div>
    </div>
  </div>
);

export default EventCard;
