import Image from 'next/image';
import Link from 'next/link';

interface Props {
  fileUrl: string;
}

const FileLink = ({ fileUrl }: Props) => (
  <div className="d-inline-block">
    <div className="d-flex flex-column align-items-stretch">
      <Link passHref href={fileUrl}>
        <a data-test-id="download-pdf" target="_blank" rel="noreferrer">
          <Image
            width={55}
            height={71}
            src="/assets/img/icons/download_pdf.svg"
            alt="Download Icon"
          />
        </a>
      </Link>
    </div>
  </div>
);

export default FileLink;
