import clsx from 'clsx';
import Image from 'next/image';

import styles from './DateBox.module.scss';

interface Props {
  startDate?: Date;
  ready?: boolean;
}

const DateBox = ({ startDate, ready }: Props) => {
  const monthsPrefix = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const date = startDate && new Date(startDate);

  const bg =
    ready !== undefined
      ? ready === true
        ? 'bg-gradient-success'
        : 'bg-gradient-danger'
      : 'bg-gradient-blue';

  return (
    <div
      className={clsx(
        bg,
        'rounded-4 me-4',
        'd-flex flex-column flex-shrink-0 justify-content-center align-items-center',
        styles['date-box'],
      )}
    >
      {date ? (
        <>
          <h2 className="m-0 text-white fw-bolder">{date.getDate()}</h2>
          <h4 className="m-0 text-white text-uppercase">{monthsPrefix[date.getMonth()]}</h4>
        </>
      ) : (
        <Image src={'/assets/img/icons/no-date.svg'} alt={''} width={50} height={50} />
      )}
    </div>
  );
};

export default DateBox;
