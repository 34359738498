import { Button, Checkbox, CheckboxInput, Form, Table, TableIconInput } from '@atoms';
import { LoadingBackdrop } from '@molecules';
import { Asset } from '@schemas';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import styles from './SculptsTable.module.scss';

interface Props {
  name: string;
  inTheme?: boolean;
  bodiesLoading: boolean;
  sculpts?: Asset[];
  handleSculptsBatch: (sculpts: Asset[]) => void;
  onAddClick: (sculpt: Asset) => void;
  onRemoveClick: (sculpt: Asset) => void;
}

const SculptsTable = ({ name, bodiesLoading, sculpts, onAddClick, onRemoveClick }: Props) => {
  const { watch, getValues } = useFormContext();

  const inAddedSculptTab = name === 'addedSculpts';

  const { fields, replace } = useFieldArray({ name });
  const values: { value: boolean; id: number }[] = watch(name);

  const selectedCount = values?.filter(({ value }) => value).length ?? 0;

  const nonDefaultSculpts = useMemo(
    () =>
      sculpts?.filter(
        (sculpt) =>
          sculpt.type === 'Hair' || (!sculpt.defaultMaleAsset && !sculpt.defaultFemaleAsset),
      ) ?? [],
    [sculpts],
  );

  const defaultSculpts = useMemo(
    () =>
      sculpts?.filter(
        (sculpt) =>
          sculpt.type === 'Body' && (sculpt.defaultMaleAsset || sculpt.defaultFemaleAsset),
      ) ?? [],
    [sculpts],
  );

  useEffect(() => {
    const currentValues: { value: boolean; id: number }[] = getValues(name);
    const selectedScultps = currentValues?.filter(({ value }) => value).map(({ id }) => id) ?? [];
    const newValues =
      nonDefaultSculpts.map((sculpt) => ({
        id: sculpt.id,
        value: selectedScultps.includes(sculpt.id),
      })) ?? [];
    replace(newValues);
  }, [nonDefaultSculpts, replace, getValues, name]);

  const toggleAll = useCallback(
    (toggle: boolean) => {
      const newValues = nonDefaultSculpts.map((sculpt) => ({ id: sculpt.id, value: toggle })) ?? [];
      replace(newValues);
    },
    [nonDefaultSculpts, replace],
  );

  return (
    <LoadingBackdrop name="sculpts-index" loading={bodiesLoading}>
      {sculpts && sculpts.length > 0 && (
        <div className={styles['sculpts-table']}>
          <Table
            titles={[
              <Checkbox
                key={`${name}.select-checkbox`}
                name="select-all-bodiesValues"
                checked={selectedCount > 0}
                onChange={() => toggleAll(selectedCount === 0)}
              />,
              ,
              'Code',
              'Name',
              'Category',
              'Gender',
              <div className="d-flex m-0  justify-content-center" key="add-icon">
                {inAddedSculptTab ? 'Remove' : 'Add'}
              </div>,
            ]}
          >
            {defaultSculpts.map((sculpt, index) => (
              <tr key={sculpt.id} className="d-table-row">
                <td />
                <td>{sculpt.code}</td>
                <td>{sculpt.name}</td>
                <td>{sculpt.category}</td>
                <td>{sculpt.gender}</td>
                <td>
                  <div key={`body-${index}-added-${'added'}`} className="text-center">
                    Default
                  </div>
                </td>
              </tr>
            ))}

            {fields &&
              fields.map((field, index) => {
                const sculpt = nonDefaultSculpts[index];
                if (!sculpt) return null;

                return (
                  <tr key={field.id} className="d-table-row">
                    <td>
                      <CheckboxInput name={`${name}.${index}.value`} />
                    </td>
                    <td>{sculpt.code}</td>
                    <td>{sculpt.name}</td>
                    <td>{sculpt.category}</td>
                    <td>{sculpt.gender}</td>
                    <td>
                      {inAddedSculptTab ? (
                        <TableIconInput
                          key={`body-${index}-added-${'added'}`}
                          name={`bodies.${index}.added`}
                          src={'/assets/img/icons/trash-icon.svg'}
                          onClick={() => {
                            onRemoveClick(sculpt);
                          }}
                        />
                      ) : (
                        <TableIconInput
                          key={`body-${index}-added-${'added'}`}
                          name={`bodies.${index}.added`}
                          src={'/assets/img/icons/add-icon.svg'}
                          onClick={() => {
                            onAddClick(sculpt);
                          }}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
          </Table>
        </div>
      )}
      <div className={clsx('d-flex justify-content-end my-3', selectedCount === 0 && 'disabled')}>
        <Button
          disabled={selectedCount === 0}
          color={selectedCount === 0 ? 'secondary' : 'primary'}
          name="changeSculpts"
          size="sm"
          type="submit"
        >
          <div className="px-5">{inAddedSculptTab ? 'Remove Sculpts' : 'Add Sculpts'}</div>
        </Button>
      </div>
    </LoadingBackdrop>
  );
};

const SculptsTableWrapper = (props: Props) => {
  const { handleSculptsBatch, sculpts, name } = props;

  return (
    <Form
      onSubmit={(values: { [name: string]: { value: boolean }[] }) => {
        const nonDefaultSculpts =
          sculpts?.filter(
            (sculpt) =>
              sculpt.type === 'Hair' || (!sculpt.defaultFemaleAsset && !sculpt.defaultMaleAsset),
          ) ?? [];
        handleSculptsBatch(nonDefaultSculpts.filter((_sculpt, index) => values[name][index].value));
      }}
    >
      <SculptsTable {...props} />
    </Form>
  );
};

export default SculptsTableWrapper;
