import axios from 'axios';
import applyConverters from 'axios-case-converter';

const config = {
  baseURL: `${process.env.NEXT_PUBLIC_API_BASE_URL}`,
  timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const options = {
  preservedKeys: ['_destroy'],
};

export const HTTP = applyConverters(axios.create(config), options);

export const HTTP_AUTH = applyConverters(axios.create(config), options);

HTTP_AUTH.interceptors.request.use(
  (config) => {
    if (config?.headers) {
      config.headers.Authorization = `Bearer ${sessionStorage.getItem('AUTH_TOKEN')}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);
