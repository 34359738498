import clsx from 'clsx';
import { ReactNode, useCallback, useEffect, useState } from 'react';

import styles from './Tabs.module.scss';

interface Props {
  children: ReactNode[];
  currentTab: number;
  onTabChange?: (tabIndex: number) => void;
  disabled?: boolean[];
}

const Tabs = ({ children, currentTab, onTabChange, disabled }: Props) => {
  const [tabWidth, setTabWidth] = useState(0);
  const [tabXPosition, setTabXPosition] = useState(0);
  const [navXPosition, setNavXPosition] = useState(0);
  const [currentTabNode, setCurrentTabNode] = useState<HTMLElement | null>(null);
  const [nav, setNav] = useState<HTMLElement | null>(null);

  const currentTabRef = useCallback((node) => {
    setCurrentTabNode(node);
  }, []);

  const navRef = useCallback((node) => {
    setNav(node);
  }, []);

  useEffect(() => {
    if (currentTabNode === null) return;

    const measureTab = () => {
      setTabWidth(currentTabNode.getBoundingClientRect().width);
      setTabXPosition(currentTabNode.getBoundingClientRect().x);
    };

    measureTab();
    window.addEventListener('resize', measureTab);

    return () => window.removeEventListener('resize', measureTab);
  }, [currentTabNode, children]);

  useEffect(() => {
    if (nav === null) return;

    const measureNav = () => {
      setNavXPosition(nav.getBoundingClientRect().x);
    };

    measureNav();
    window.addEventListener('resize', measureNav);

    return () => window.removeEventListener('resize', measureNav);
  }, [nav]);

  return (
    <nav ref={navRef} className="position-relative mb-4">
      <ul className={clsx('text-dark list-unstyled d-flex', 'border-bottom border-gray-500 m-0')}>
        {children?.map((child, index) => (
          <li
            ref={index === currentTab ? currentTabRef : undefined}
            className={clsx(
              'me-4 py-2 fs-4',
              index === currentTab && 'font-weight-bolder text-primary',
              disabled?.[index] && 'opacity-50 pe-none',
            )}
            key={index}
            role="button"
            data-test-id={`tab-${index}`}
            onClick={() => onTabChange && onTabChange(index)}
          >
            {child}
          </li>
        ))}
      </ul>

      <div
        className={clsx(
          'position-absolute top-100 translate-middle-y',
          'rounded-pill bg-primary',
          styles['highlight-border'],
        )}
        style={{ width: tabWidth, left: tabXPosition - navXPosition }}
      />
    </nav>
  );
};
export default Tabs;
