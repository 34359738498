import { Button } from '@atoms';
import clsx from 'clsx';
import Image from 'next/image';
import { MouseEvent, PropsWithChildren, useEffect, useState } from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  onAction?: () => void;
  actionText?: string;
  title: string;
  name: string;
  closeText?: string;
}

const Modal = ({
  open,
  onClose,
  onAction,
  actionText,
  children,
  title,
  name,
  closeText,
}: PropsWithChildren<Props>) => {
  // Display delays the d-none to show the animation
  const [display, setDisplay] = useState(open);

  useEffect(() => {
    const timer = setTimeout(
      () => {
        setDisplay(open);
      },
      open ? 0 : 150,
    );

    return () => {
      clearTimeout(timer);
    };
  }, [open]);

  const handleModalClick = (event: MouseEvent) => {
    const target = event.target as Element;
    if (target.getAttribute('role') === 'dialog') {
      onClose();
    }
  };

  return (
    <>
      <div
        className={clsx(
          'modal-backdrop fade',
          open && display && 'show',
          open || display ? 'd-block' : 'd-none',
        )}
      />
      <div
        data-test-id={`modal-${name.replace(/\s/g, '-')}`}
        className={clsx(
          'modal fade',
          open && display && 'show',
          open || display ? 'd-block' : 'd-none',
        )}
        tabIndex={-1}
        onClick={handleModalClick}
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header position-relative text-dark justify-content-center">
              <h3 className="modal-title">{title}</h3>
              <button
                onClick={onClose}
                type="button"
                data-test-id={`modal-${name.replace(/\s/g, '-')}-close-button`}
                className="btn-close h4 position-absolute end-0 me-3"
              >
                <Image src="/assets/img/icons/close-modal.svg" alt="Close" width={27} height={24} />
              </button>
            </div>
            <div className="modal-body">{children}</div>
            <div className="d-flex justify-content-center pt-1 pb-3">
              <div className="mx-3">
                <Button
                  onClick={onClose}
                  color="secondary"
                  outline
                  name={`modal-${name}-dismiss-button`}
                >
                  {closeText ?? 'Close'}
                </Button>
              </div>
              {actionText && (
                <div className="mx-3">
                  <Button onClick={onAction} name={`modal-${name}-action-button`}>
                    {actionText}{' '}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Modal;
