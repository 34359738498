import Button from '@atoms/Button/Button';

interface Props {
  disableButton: boolean;
  onCancel: () => void;
  prefix: string;
  isLastTab?: boolean;
}

const FormControlButtons = ({ prefix, disableButton, onCancel, isLastTab = false }: Props) => (
  <div className="d-flex justify-content-center mt-6 mb-4">
    <Button outline color="dark" name={`${prefix}-group-cancel`} size="sm" onClick={onCancel}>
      <div className="px-5">Clear</div>
    </Button>

    <div className="mx-3" />
    <Button
      disabled={disableButton}
      color="primary"
      size="sm"
      type="submit"
      name={`${prefix}-group-submit`}
    >
      <div className="px-5">{isLastTab ? 'Save' : 'Next'}</div>
    </Button>
  </div>
);
export default FormControlButtons;
