import type { AFError } from '@schemas/AFError';
import type { GroupFormResponse, GroupValues, TextureCreatorGroupValues } from '@schemas/Group';
import { Group } from '@schemas/Group';
import axios from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { useMutation, UseMutationOptions } from 'react-query';
import { toFormData } from 'utils/FormUtils';

import { HTTP_AUTH } from './api';

const groupsUrl = '/api/cafe/v1/groups';

interface GroupsResponse {
  data: Group[];
  total: number;
}

export const useGroups = (
  offset?: number,
  limit?: number,
): UseQueryResult<GroupsResponse, AFError> =>
  useQuery('groups', async () => {
    try {
      const response = await HTTP_AUTH.get<GroupsResponse>(groupsUrl, {
        params: { offset, limit },
      });
      return response.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data) {
          throw error.response.data as AFError;
        } else {
          throw { errorMessages: [error.message] };
        }
      } else {
        throw { errorMessages: ['An error occurred'] };
      }
    }
  });

const companyGroupUrl = (id?: string | string[]) => `/api/cafe/v1/groups/${id}`;

export const useCompanyGroup = (id?: string | string[]): UseQueryResult<Group, AFError> =>
  useQuery(`groups-${id}`, async () => {
    try {
      const response = await HTTP_AUTH.get<Group>(companyGroupUrl(id));

      return response.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data) {
          throw error.response.data as AFError;
        } else {
          throw { errorMessages: error.message };
        }
      } else {
        throw { errorMessages: 'An error occurred' };
      }
    }
  });

const groupCheckName = '/api/cafe/v1/groups/check_name';
const groupCheckCode = '/api/cafe/v1/groups/check_code';

export const useGroupsCheckDuplicate = (
  checkAttribute: string,
  value: string,
): UseQueryResult<boolean, AFError> =>
  useQuery(
    checkAttribute,
    async () => {
      try {
        let response;
        if (checkAttribute === 'name') {
          response = await HTTP_AUTH.get<Group[]>(`${groupCheckName}/${value}`);
        } else {
          response = await HTTP_AUTH.get<Group[]>(`${groupCheckCode}/${value}`);
        }

        return response.data;
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          if (error.response?.data) {
            throw error.response.data as AFError;
          } else {
            throw { errorMessages: [error.message] };
          }
        } else {
          throw { errorMessages: ['An error occurred'] };
        }
      }
    },
    { enabled: false },
  );

const createGroupUrl = '/api/cafe/v1/groups';
const updateGroupUrl = (groupId?: number) => `/api/cafe/v1/groups/${groupId}`;

export const useCreateGroup = (
  options: UseMutationOptions<GroupFormResponse, AFError, GroupValues>,
) =>
  useMutation(async (data) => {
    try {
      if (data.group.image instanceof FileList) {
        data.group.image = data.group.image[0];
      }

      const iconTypes = ['front', 'back', 'right', 'left', 'shoulder', 'hat', 'hat2', 'plaque'];

      iconTypes.forEach((iconType) => {
        data.group[`${iconType}IconType` as keyof typeof data.group] = 'none' as never;
      });

      data.group.texturesAttributes = data.group.addedSculpts.map((texture) => ({
        approvalState: 'approved',
        assetId: texture.id,
      }));

      const response = await HTTP_AUTH.post(createGroupUrl, toFormData(data));

      return {
        group: response.data,
        status: response.status,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw {
          errorMessages:
            error.response?.data.errorMessages ?? error.response?.data.error ?? error.message,
        };
      } else {
        throw { errorMessages: 'An error occurred' };
      }
    }
  }, options);

export const useEditGroup = (
  options: UseMutationOptions<GroupFormResponse, AFError, GroupValues>,
  groupId: number,
) =>
  useMutation(async (data) => {
    try {
      if (data.group.image instanceof FileList) {
        data.group.image = data.group.image[0];
      }

      data.group.texturesAttributes = data.group.addedSculpts.map((texture) => ({
        approvalState: 'approved',
        id: texture.textureId,
        assetId: texture.id,
      }));

      data.group.texturesAttributes = [
        ...data.group.texturesAttributes,

        ...data.group.toDestroy.map((texture) => ({
          id: texture.textureId,
          assetId: texture.id,
          _destroy: true,
        })),
      ];

      const response = await HTTP_AUTH.patch(updateGroupUrl(groupId), toFormData(data));
      return {
        status: response.status,
        group: response.data,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw {
          errorMessages:
            error.response?.data.errorMessages ?? error.response?.data.error ?? error.message,
        };
      } else {
        throw { errorMessages: 'An error occurred' };
      }
    }
  }, options);

const addIconsParams = (data: TextureCreatorGroupValues) => {
  const icons = [
    'frontIcon',
    'backIcon',
    'rightIcon',
    'leftIcon',
    'shoulderIcon',
    'hatIcon',
    'plaqueIcon',
    'hat2Icon',
  ];

  icons.forEach((icon) => {
    if (data.group[icon as keyof typeof data.group]) {
      data.group[`${icon}Type` as keyof typeof data.group] = 'image';
    }
  });
};

export const useEditTextureCreatorGroup = (
  options: UseMutationOptions<GroupFormResponse, AFError, TextureCreatorGroupValues>,
  groupId?: number,
) =>
  useMutation(async (data) => {
    try {
      addIconsParams(data);

      const response = await HTTP_AUTH.patch(updateGroupUrl(groupId), toFormData(data));

      return {
        status: response.status,
        group: response.data,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw { errorMessages: error.response?.data.error ?? error.message };
      } else {
        throw { errorMessages: 'An error occurred' };
      }
    }
  }, options);

export const useDeleteGroup = (
  options: UseMutationOptions<DeleteGroupResponse, AFError>,
  groupId: number,
) =>
  useMutation(async () => {
    try {
      const response = await HTTP_AUTH.delete(updateGroupUrl(groupId));
      return {
        status: response.status,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw { errorMessages: error.response?.data.error ?? error.message };
      } else {
        throw { errorMessages: 'An error occurred' };
      }
    }
  }, options);

interface DeleteGroupResponse {
  status: number;
}

export const useGroupPlaqueBodies = (id?: number): UseQueryResult<boolean, AFError> =>
  useQuery(`groups-${id}-plaque-bodies`, async () => {
    try {
      const response = await HTTP_AUTH.get<Group>(`${updateGroupUrl(id)}/has_plaque_bodies`);

      return response.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data) {
          throw error.response.data as AFError;
        } else {
          throw { errorMessages: [error.message] };
        }
      } else {
        throw { errorMessages: ['An error occurred'] };
      }
    }
  });
