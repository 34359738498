import { useSignOut } from '@api/session';
import { useQueryClient } from 'react-query';

const SignOutButton = () => {
  const queryClient = useQueryClient();

  const signOutMutation = useSignOut({
    onSuccess: () => {
      queryClient.invalidateQueries('user-token');
    },
  });

  return (
    <span
      role="button"
      className="text-body fw-bolder"
      onClick={() => signOutMutation.mutate()}
      data-test-id="button-sign-out"
    >
      Sign Out
    </span>
  );
};
export default SignOutButton;
