import type { AFError } from '@schemas/AFError';
import type { Event, EventFormResponse } from '@schemas/Event';
import type { EventValues, ThemesEventValues } from '@schemas/eventSchema';
import type { Group } from '@schemas/Group';
import type { MarketingMaterialsEventUpdateValues } from '@schemas/MarketingMaterial';
import type { EventTechnologyChecklistValues } from '@schemas/TechnologyChecklist';
import {
  UnlockCodeBatch,
  UnlockCodeBatchForm,
  UnlockCodeBatchFormResponse,
} from '@schemas/UnlockCodeBatch';
import axios from 'axios';
import { useMutation, UseMutationOptions, useQuery, UseQueryResult } from 'react-query';

import { HTTP_AUTH } from './api';

const eventsUrl = '/api/cafe/v1/events';

export const useEvents = (): UseQueryResult<Event[], AFError> =>
  useQuery('events', async () => {
    try {
      const response = await HTTP_AUTH.get<Event[]>(eventsUrl);

      return response.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data) {
          throw error.response.data as AFError;
        } else {
          throw { errorMessages: [error.message] };
        }
      } else {
        throw { errorMessages: ['An error occurred'] };
      }
    }
  });

const eventUrl = (eventId?: string | string[]) => `/api/cafe/v1/events/${eventId}`;

export const useEvent = (eventId?: string | string[]): UseQueryResult<Event, AFError> =>
  useQuery(['event', eventId], async () => {
    try {
      const response = await HTTP_AUTH.get<Event>(eventUrl(eventId));

      return response.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data) {
          throw error.response.data as AFError;
        } else {
          throw { errorMessages: error.message };
        }
      } else {
        throw { errorMessages: 'An error occurred' };
      }
    }
  });

export const useCreateEvent = (options: UseMutationOptions<Event, AFError, EventValues>) =>
  useMutation(async (data) => {
    try {
      const response = await HTTP_AUTH.post<Event>(eventsUrl, data);

      return response.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data) {
          throw error.response.data as AFError;
        } else {
          throw { errorMessages: [error.message] };
        }
      } else {
        throw { errorMessages: ['An error occurred'] };
      }
    }
  }, options);

const updateEventUrl = (eventId: number) => `/api/cafe/v1/events/${eventId}`;

export const useUpdateEvent = (
  options: UseMutationOptions<EventFormResponse, AFError, EventValues>,
  eventId: number,
) =>
  useMutation(async (data) => {
    try {
      const response = await HTTP_AUTH.patch<Event>(updateEventUrl(eventId), data);

      return {
        status: response.status,
        event: response.data,
      };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data) {
          throw error.response.data as AFError;
        } else {
          throw { errorMessages: [error.message] };
        }
      } else {
        throw { errorMessages: ['An error occurred'] };
      }
    }
  }, options);

const eventUnlocksUrl = (eventId?: number) => `/api/cafe/v1/events/${eventId}/unlock_code_batches`;

export const useCreateUnlockCodes = (
  options: UseMutationOptions<UnlockCodeBatchFormResponse, AFError, UnlockCodeBatchForm>,
  eventId: number,
) =>
  useMutation(async (data) => {
    try {
      const response = await HTTP_AUTH.post<Event>(eventUnlocksUrl(eventId), data);

      return {
        status: response.status,
      };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data) {
          throw error.response.data as AFError;
        } else {
          throw { errorMessages: [error.message] };
        }
      } else {
        throw { errorMessages: ['An error occurred'] };
      }
    }
  }, options);

export const useEventUnlocksUrl = (eventId?: number): UseQueryResult<UnlockCodeBatch[], AFError> =>
  useQuery(
    ['eventUnlocks', eventId],
    async () => {
      try {
        const response = await HTTP_AUTH.get<Event>(eventUnlocksUrl(eventId));

        return response.data;
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          if (error.response?.data) {
            throw error.response.data as AFError;
          } else {
            throw { errorMessages: error.message };
          }
        } else {
          throw { errorMessages: 'An error occurred' };
        }
      }
    },
    {
      enabled: eventId !== undefined,
      refetchInterval: (batch?: UnlockCodeBatch[]) => {
        if (batch && batch.length !== 0 && batch[0].status === 'pending') {
          return 30000;
        }

        return false;
      },
    },
  );

export const useAddThemeToEvent = (
  options: UseMutationOptions<EventFormResponse, AFError, ThemesEventValues>,
  eventId: number,
) =>
  useMutation(async (data) => {
    try {
      const response = await HTTP_AUTH.patch<Event>(updateEventUrl(eventId), data);

      return {
        status: response.status,
        event: response.data,
      };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data) {
          throw error.response.data as AFError;
        } else {
          throw { errorMessages: [error.message] };
        }
      } else {
        throw { errorMessages: ['An error occurred'] };
      }
    }
  }, options);

export const useUpdateEventActiveThemes = (
  options: UseMutationOptions<EventFormResponse, AFError, ThemesEventValues>,
  eventId: number,
) =>
  useMutation(async (data) => {
    try {
      const response = await HTTP_AUTH.patch<Event>(updateEventUrl(eventId), data);

      return {
        status: response.status,
        event: response.data,
      };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data) {
          throw error.response.data as AFError;
        } else {
          throw { errorMessages: [error.message] };
        }
      } else {
        throw { errorMessages: ['An error occurred'] };
      }
    }
  }, options);

export const useUpdateEventMarketingMaterials = (
  options: UseMutationOptions<EventFormResponse, AFError, MarketingMaterialsEventUpdateValues>,
  eventId: number,
) =>
  useMutation(async (data) => {
    try {
      const response = await HTTP_AUTH.patch<Event>(updateEventUrl(eventId), data);

      return {
        status: response.status,
        event: response.data,
      };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data) {
          throw error.response.data as AFError;
        } else {
          throw { errorMessages: [error.message] };
        }
      } else {
        throw { errorMessages: ['An error occurred'] };
      }
    }
  }, options);

interface IndexResponse {
  data: Group[];
  total: number;
}

export const useActiveThemes = (
  eventId: number,
  offset?: number,
  limit?: number,
  sortBy?: string,
): UseQueryResult<IndexResponse, AFError> =>
  useQuery(
    ['active-themes', eventId, offset, limit, sortBy],
    async () => {
      try {
        const response = await HTTP_AUTH.get<IndexResponse>(`${eventsUrl}/${eventId}/themes`, {
          params: { offset: offset, limit: limit, sortBy: sortBy },
        });

        return response.data;
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          if (error.response?.data) {
            throw error.response.data as AFError;
          } else {
            throw { errorMessages: error.message };
          }
        } else {
          throw { errorMessages: 'An error occurred' };
        }
      }
    },
    { keepPreviousData: true },
  );

export const useFeturedThemes = (eventId: number): UseQueryResult<IndexResponse, AFError> =>
  useQuery(
    ['featured-themes', eventId],
    async () => {
      try {
        const response = await HTTP_AUTH.get<IndexResponse>(`${eventsUrl}/${eventId}/themes`, {
          params: { offset: 0, limit: 3, sortBy: 'featured' },
        });

        response.data.data = response.data.data.filter((theme) => !!theme.priority);

        return response.data;
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          if (error.response?.data) {
            throw error.response.data as AFError;
          } else {
            throw { errorMessages: error.message };
          }
        } else {
          throw { errorMessages: 'An error occurred' };
        }
      }
    },
    { keepPreviousData: true },
  );

export const useRemoveThemeFromEvent = (
  options: UseMutationOptions<{}, AFError, ThemesEventValues>,
  eventId: number,
) =>
  useMutation(async (data) => {
    try {
      await HTTP_AUTH.patch<Event>(updateEventUrl(eventId), data);

      return {};
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw { errorMessages: error.response?.data.error ?? error.message };
      } else {
        throw { errorMessages: ['An error occurred'] };
      }
    }
  }, options);

export const useUpdateEventTechnologyChecklist = (
  options: UseMutationOptions<EventFormResponse, AFError, EventTechnologyChecklistValues>,
  eventId: number,
) =>
  useMutation(async (data) => {
    try {
      const response = await HTTP_AUTH.patch<Event>(updateEventUrl(eventId), data);

      return {
        status: response.status,
        event: response.data,
      };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        if (error.response?.data) {
          throw error.response.data as AFError;
        } else {
          throw { errorMessages: [error.message] };
        }
      } else {
        throw { errorMessages: ['An error occurred'] };
      }
    }
  }, options);
