import { LoadingIndicator } from '@atoms';
import { AssetCategory } from '@schemas';
import clsx from 'clsx';
import throttle from 'lodash.throttle';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';

import styles from './AssetsTable.module.scss';

interface Props {
  categories: AssetCategory[];
  selectedAsset?: number;
  onCategoryFocus: (categoryId: number) => void;
  focusedCategory?: number;
  onAssetSelect: (assetId: number) => void;
  loading?: boolean;
}

const THROTTLE_TIME = 100;
const VISIBLE_THRESHOLD = 0.4;

const checkVisible = (
  top: number,
  bottom: number,
  containerTop: number,
  containerBottom: number,
) => {
  const topMax = Math.max(top, containerTop);
  const bottomMin = Math.min(containerBottom, bottom);

  const visiblePercentage = (bottomMin - topMax) / (containerBottom - containerTop);

  return {
    visiblePercentage,
    visible: visiblePercentage > VISIBLE_THRESHOLD,
  };
};

const AssetsTable = ({
  categories,
  selectedAsset,
  onAssetSelect,
  onCategoryFocus,
  focusedCategory,
  loading,
}: Props) => {
  const [scrollingToCategory, setScrollingToCategory] = useState(false);
  const container = useRef<HTMLDivElement>(null);

  const onScroll = throttle(() => {
    if (!container.current) {
      return;
    }

    const { top: containerTop, bottom: containerBottom } =
      container.current?.getBoundingClientRect();

    const category = container.current.querySelector(`[data-category="${focusedCategory}"]`);
    // If we are scrolling from click on category, don't check scroll until element is reached
    if (category && scrollingToCategory) {
      const { top: categoryTop, bottom: categoryBottom } = category.getBoundingClientRect();
      const { visiblePercentage } = checkVisible(
        categoryTop,
        categoryBottom,
        containerTop,
        containerBottom,
      );

      if (visiblePercentage < 0.6) {
        return;
      }

      setScrollingToCategory(false);
    }

    const visibleCategories: { id: number; visiblePercentage: number }[] = [];

    for (let i = 0; i < container.current.children.length; i++) {
      const child = container.current.children[i];
      const { top: childTop, bottom: childBottom } = child.getBoundingClientRect();

      const { visiblePercentage, visible } = checkVisible(
        childTop,
        childBottom,
        containerTop,
        containerBottom,
      );

      if (child instanceof HTMLElement && visible) {
        const categoryId = child.dataset.category;
        categoryId && visibleCategories.push({ id: parseInt(categoryId, 10), visiblePercentage });
      }
    }

    if (visibleCategories.length === 0) {
      return;
    }

    const { id } = visibleCategories.sort(
      ({ visiblePercentage: a }, { visiblePercentage: b }) => b - a,
    )[0];

    onCategoryFocus(id);
  }, THROTTLE_TIME);

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true);
    return () => document.removeEventListener('scroll', onScroll, true);
  }, [onScroll]);

  useEffect(() => {
    if (focusedCategory && container.current) {
      const category = container.current.querySelector(`[data-category="${focusedCategory}"]`);
      if (category) {
        category.scrollIntoView({ behavior: 'smooth' });
        setScrollingToCategory(true);
      }
    }
  }, [focusedCategory]);

  return (
    <div
      className="overflow-y-auto overflow-x-hidden ms-3 me-2 pink-scrollbar position-relative pb-5"
      ref={container}
    >
      {loading && (
        <div className="position-absolute h-100 w-100 d-flex justify-content-center align-items-center">
          <LoadingIndicator />
        </div>
      )}
      {categories.map(({ id: categoryId, name, assets }) => (
        <div key={categoryId} data-category={`${categoryId}`}>
          <label className="text-white text-capitalize position-aboslute top-0 start-0">
            {name}
          </label>
          <div className="position-relative row row-cols-3 row-cols-xl-4 px-4 px-lg-5">
            {assets.map(({ id, icon }) => (
              <div
                data-test-id={`asset-button-${id}`}
                key={id}
                className="col position-relative px-0"
                role="button"
                onClick={() => onAssetSelect(id)}
              >
                <div className={clsx(selectedAsset === id && styles.selected)} />
                <Image
                  src={icon.url}
                  priority
                  alt="asset"
                  layout="responsive"
                  height={500}
                  width={500}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AssetsTable;
