import { useDeleteGroup } from '@api/groups';
import { LinkButton } from '@atoms';
import clsx from 'clsx';
import Image from 'next/image';
import { forwardRef, Ref, useState } from 'react';

import styles from './GroupCard.module.scss';

interface Props {
  id: number;
  name: string;
  image: string;
  selectableCard?: boolean;
  selected?: boolean;
  selectAction?: () => void;
  closeAction?: () => void;
  extraOptions?: boolean;
}

const GroupCard = forwardRef(
  (
    {
      id,
      name,
      image,
      selectableCard = false,
      selected = false,
      selectAction,
      closeAction,
      extraOptions,
    }: Props,
    ref?: Ref<HTMLDivElement>,
  ) => {
    const [showOptionsModal, setShowOptionsModal] = useState(false);
    const [showClose, setShowClose] = useState(false);

    const mutation = useDeleteGroup(
      {
        onSuccess: () => {
          closeAction && closeAction();
        },
      },
      id,
    );

    return (
      <div
        className={clsx(
          'card h-100 position-relative',
          'border-1 shadow-none',
          selectableCard && 'p-1 p-xl-2',
          selectableCard && selected && 'border-3 outline border-primary shadow',
        )}
        data-test-id={`group-card-${id}`}
        onClick={() => selectAction && selectAction()}
        ref={ref}
        onMouseEnter={() => selectableCard && setShowClose(true)}
        onMouseLeave={() => selectableCard && setShowClose(false)}
      >
        {showClose && (
          <div className="position-absolute d-flex top-0 end-0 m-2 p-1">
            <Image
              data-test-id="sidebar-close-button"
              src={'/assets/img/icons/close-menu.svg'}
              alt=""
              width="15"
              height="15"
              role="button"
              className={clsx('m-auto', styles['gray-filter'])}
              onClick={closeAction}
            />
          </div>
        )}
        {extraOptions && (
          <div className="position-absolute d-flex top-0 end-0 mt-3 me-2 p-1">
            <Image
              data-test-id="group-card-more-options-button"
              src={'/assets/img/icons/more-options.svg'}
              alt=""
              width="15"
              height="25"
              role="button"
              className="m-auto"
              onClick={() => setShowOptionsModal((prevValue) => !prevValue)}
            />
          </div>
        )}
        <div
          className={clsx(
            'card-body text-center d-flex',
            'flex-column justify-content-between',
            'p-5 pt-4',
          )}
        >
          <div>
            <div
              className={clsx(
                styles['image-container'],
                'd-flex justify-content-center align-items-center',
                'img-fluid m-auto',
              )}
            >
              <Image
                src={image || '/assets/img/logos/Generic_Logo_Text.png'}
                alt={name}
                height={100}
                width={100}
              />
            </div>
            <h4 className="card-text fw-bolder my-3" data-test-id={`group-card-name-${id}`}>
              {name}
            </h4>
          </div>

          {!selectableCard && (
            <div className="align-self-center">
              <LinkButton
                name={`manage-group-${id}`}
                size="lg"
                href={`/themes/${id}/edit`}
                color="primary"
              >
                <span className="white-space-pre fs-6 p-0 p-xxl-2">Manage</span>
              </LinkButton>
            </div>
          )}
        </div>
        {showOptionsModal && (
          <div
            className={clsx(
              styles['more-options-modal'],
              'p-3 pe-4 rounded-3 position-absolute z-index-1',
            )}
          >
            <div className="d-flex flex-column">
              <a
                href={`/themes/${id}/duplicate_theme`}
                data-test-id="duplicate-theme-button"
                className="text-gray-600 mb-2"
              >
                Duplicate
              </a>
              <a
                role="button"
                data-test-id="delete-theme-button"
                className="text-gray-600"
                onClick={() => mutation.mutate()}
              >
                Remove
              </a>
            </div>
          </div>
        )}
      </div>
    );
  },
);

GroupCard.displayName = 'GroupCard';

export default GroupCard;
