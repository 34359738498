export function toFormData(object: unknown): FormData {
  const data = new FormData();

  const appendKeys = (obj: unknown, prefix = '') => {
    if (obj === undefined || obj === null) {
      return;
    } else if (typeof obj === 'string') {
      data.append(prefix, obj);
    } else if (typeof obj === 'number' || typeof obj === 'boolean' || obj instanceof Date) {
      data.append(prefix, obj.toString());
    } else if (obj instanceof Array) {
      obj.forEach((item) => {
        appendKeys(item, `${prefix}[]`);
      });
    } else if (obj instanceof File) {
      data.append(prefix, obj);
    } else if (obj instanceof FileList) {
      for (let index = 0; index < obj.length; index++) {
        const value = obj[index];
        appendKeys(value, `${prefix}[]`);
      }
    } else {
      const parsedObj = obj as { [key: string]: unknown };
      Object.keys(parsedObj).forEach((key) => {
        const newPrefix = prefix ? `${prefix}[${key}]` : key;
        appendKeys(parsedObj[key], newPrefix);
      });
    }
  };

  appendKeys(object);

  return data;
}
