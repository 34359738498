import { HTTP_AUTH } from '@api/api';
import { useEventUnlocksUrl } from '@api/events';
import { LoadingIndicator, Table, TableIconInput } from '@atoms';
import LoadingBackdrop from '@molecules/LoadingBackdrop/LoadingBackdrop';
import { UnlockCodeBatch } from '@schemas/UnlockCodeBatch';
import clsx from 'clsx';
import { useMemo } from 'react';

import styles from './EventUnlockCodesTable.module.scss';

interface Props {
  eventId: number;
  eventName: string;
}

const EventUnlockCodesTable = ({ eventId, eventName }: Props) => {
  const { data: unlockCodeBatches, status: unlockStatus } = useEventUnlocksUrl(eventId);

  const formattedUnlockCodeBatches = useMemo(
    () =>
      unlockCodeBatches?.map((row) => {
        const date = new Date(row.createdAt ?? '');
        return {
          ...row,
          createdAt: date.toLocaleDateString(),
        };
      }),
    [unlockCodeBatches],
  );

  const formattedCsv = (batch: UnlockCodeBatch) =>
    HTTP_AUTH({
      url: `${batch.linkToCsv}.csv`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${eventName} - ${batch.createdAt}.csv`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });

  return (
    <LoadingBackdrop name="eventUnlocks" loading={unlockStatus == 'loading'}>
      <div className={clsx('col overflow-y-auto', styles['unlock-code-container'])}>
        {unlockCodeBatches && (
          <Table titles={['Batch', 'Created', 'Csv']}>
            {formattedUnlockCodeBatches?.map((batch) => (
              <tr key={batch.id} className="d-table-row">
                <td>{batch.id}</td>
                <td>{batch.createdAt}</td>
                <td>
                  {batch.status != 'completed' ? (
                    <LoadingIndicator />
                  ) : (
                    <TableIconInput
                      name="Download CSV"
                      src="/assets/img/icons/csv-download-icon.svg"
                      onClick={() => {
                        batch.linkToCsv && formattedCsv(batch);
                      }}
                    />
                  )}
                </td>
              </tr>
            ))}
          </Table>
        )}
      </div>
    </LoadingBackdrop>
  );
};
export default EventUnlockCodesTable;
