import 'twix';

import { Alert, CopyCodeCard } from '@atoms';
import { Event } from '@schemas/Event';
import moment from 'moment';
import { useMemo } from 'react';

interface Props {
  event?: Event;
}

const EventDetailsInfo = ({ event }: Props) => {
  const dateString = useMemo(() => {
    if (!event?.startDate) return '';

    const startDate = new Date(event.startDate);

    if (!event?.endDate) {
      return moment(startDate).format('MMMM DD, YYYY');
    }

    const endDate = new Date(event.endDate);

    return moment(startDate).twix(endDate, { allDay: true }).format({ implicitYear: false });
  }, [event?.startDate, event?.endDate]);

  return (
    <div className="display-flex">
      <div className="d-inline-block me-5">
        <div>
          <h5 className="text-bolder">Date</h5>
          <span>{dateString}</span>
        </div>
        <div className="mt-4">
          <h5 className="text-bolder">Status</h5>
          <div className="text-center">
            <Alert inline={true} color={event?.ready ? 'success' : 'danger'}>
              {event?.ready ? 'CONFIRMED' : 'ACTION REQUIRED'}
            </Alert>
          </div>
        </div>
      </div>
      <div className="d-inline-block">
        <div>
          <h5 className="text-bolder">Expected number of sales</h5>
          <span>{event?.expectedSales || 0}</span>
        </div>
        <div className="mt-4">
          <h5 className="text-bolder">Event Code</h5>
          <CopyCodeCard eventCode={event?.code ?? '123456'} enable={event?.ready ? true : false} />
        </div>
      </div>
    </div>
  );
};
export default EventDetailsInfo;
