import { useGroupsCheckDuplicate } from '@api/groups';
import { TextInput } from '@atoms';
import { debounce } from 'debounce';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface Props {
  label?: string;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  variant?: 'main' | 'auth' | 'compact';
  onChange?: () => void;
}

const GroupUniqueInput = ({
  label,
  name,
  placeholder,
  disabled = false,
  variant = undefined,
  onChange,
}: Props) => {
  const { setError, watch, clearErrors } = useFormContext();

  const inputValue = watch(name);

  const { data: isDuplicated, error, refetch } = useGroupsCheckDuplicate(name, inputValue);

  const debounceOnChange = debounce(() => {
    onChange && onChange();
    refetch();
  }, 1000);

  useEffect(() => {
    if (inputValue && (error || isDuplicated)) {
      setError(name, {
        type: 'manual',
        message: error || isDuplicated ? 'This group is already taken' : undefined,
      });
    } else {
      clearErrors(name);
    }
  }, [inputValue, isDuplicated, error, setError, clearErrors, name]);

  return (
    <TextInput
      type="text"
      label={label}
      name={name}
      disabled={disabled}
      placeholder={placeholder}
      onChange={debounceOnChange}
      required
      isDebounce
      variant={variant}
    />
  );
};
export default GroupUniqueInput;
