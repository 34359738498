import { Button, CheckboxInput, Form } from '@atoms';
import clsx from 'clsx';
import Image from 'next/image';
import { useCallback, useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';

import styles from './SculptFilter.module.scss';

interface WrapperProps {
  title: string;
  labels: string[];
  applyFilter: (labels: string[]) => void;
}

interface Props extends WrapperProps {
  open: boolean;
  setOpen: (open: boolean | ((prevValue: boolean) => boolean)) => void;
}

const SculptFilter = ({ title, labels, applyFilter, open, setOpen }: Props) => {
  const name = `filter-${title}`;
  const { fields, replace } = useFieldArray({ name });

  useEffect(() => {
    replace(labels.map((label) => ({ label, selected: false })));
  }, [labels, replace]);

  const resetFilter = useCallback(() => {
    replace(labels.map((label) => ({ label, selected: false })));
    applyFilter([]);
    setOpen(false);
  }, [applyFilter, labels, replace, setOpen]);

  return (
    <div>
      <div
        className="rounded-pill border border-1 border-highlight d-inline-flex align-items-center py-2 px-3"
        role="button"
        data-test-id={`sculpt-${title}-filter`}
        onClick={() => setOpen((prevValue) => !prevValue)}
      >
        <h6 className="text-highlight mb-0 me-3 font-weight-bolder">{title}</h6>
        {open ? (
          <Image
            src={'/assets/img/down-arrow-highlight.svg'}
            alt="down-arrow-highlight"
            width={15}
            height={15}
          />
        ) : (
          <Image
            src={'/assets/img/up-arrow-highlight.svg'}
            alt="up-arrow-highlight"
            width={15}
            height={15}
          />
        )}
      </div>
      {open && (
        <div
          className={clsx(
            styles['sculpt-filter-dropdown'],
            'position-absolute p-3 rounded-3 top-15 top-100',
          )}
        >
          <div className={clsx(styles['option-filter-container'], 'overflow-auto')}>
            {fields.map((field, index) => (
              <div key={field.id} className="mb-3">
                <CheckboxInput
                  name={`${name}.${index}.selected`}
                  label={labels[index]}
                  isDropdown
                />
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <h5
              className="text-primary mb-0 me-3"
              role="button"
              data-test-id="clear-filters-button"
              onClick={resetFilter}
            >
              Clear
            </h5>
            <Button name="save-filter" type="submit" size="sm">
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const SculptFilterWrapper = (props: WrapperProps) => {
  const [open, setOpen] = useState(false);
  const { applyFilter, title } = props;

  return (
    <Form
      onSubmit={(values: { [name: string]: { label: string; selected: boolean }[] }) => {
        const filteredLabels = values[`filter-${title}`]
          .filter((option) => option.selected)
          .map((option) => option.label);

        applyFilter(filteredLabels);
        setOpen(false);
      }}
    >
      <SculptFilter {...props} open={open} setOpen={(value) => setOpen(value)} />
    </Form>
  );
};

export default SculptFilterWrapper;
